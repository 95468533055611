import React, { useCallback, useMemo, useRef } from "react";
import { type SubmitErrorHandler, type SubmitHandler } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { z } from "zod";

import { useEventOrNetworkingHub } from "@src/hooks/useEventOrNetworkingHub";
import {
  UseFormWithPersistentValidationReturn,
  canSubmitForm,
  useFormWithPersistentValidation,
} from "@src/hooks/useFormWithPersistentValidation";
import { Routes } from "@src/hooks/useIsRoute";
import { PostMessageEvent, useEmitEventCallback } from "@src/providers/embed";
import { useRegisterUser } from "@src/providers/registration";

import Link from "@mui/material/Link";

import Form from "@src/components/Forms/Form";
import Button from "@src/components/buttons/Button";

import CustomQuestion from "../inputs/CustomQuestion";
import RegistrationSidebarView from "./RegistrationSidebarView";

import {
  MAX_TEXT_LONG_LENGTH,
  type RegistrationQuestion,
  RegistrationQuestionAnswerType,
  RegistrationQuestionAnswer,
  PredefinedUTMQuestionIdToFieldMap,
  isUTMFieldQuestion,
} from "@src/contracts/event/event";
import { useHasEventEnded } from "@src/providers/EventProvider";
import { useJoinEvent } from "../hooks/useJoinEvent";
import { cn } from "@introvoke/react/styles/utils";
import { CreateUserSchema } from "@src/schemas/user";
import { isEmailFromBusinessDomain } from "@src/helpers/email";
import { useEventVideoType } from "@src/providers/EventStateProvider";
import { VideoType } from "@src/models/eventType";

const generateFormSchema = ({
  customQuestions,
  allowOnlyBusinessEmails,
}: {
  allowOnlyBusinessEmails?: boolean;
  customQuestions: RegistrationQuestion[];
}) => {
  const FormSchema = CreateUserSchema.extend({
    terms: z
      .boolean()
      .refine((val) => !!val, "You must agree to the Terms and Conditions")
      .optional(),
    privacy: z
      .boolean()
      .refine((val) => !!val, "You must agree to the Privacy Policy")
      .optional(),
    customQuestions: z.array(
      // allow supported types of answers but always transform to string
      z
        .string()
        .or(z.number())
        .or(z.boolean())
        .transform((val) => String(val).trim()),
    ),
  })
    // Only allow business emails if the option is on
    .refine(
      (values) =>
        allowOnlyBusinessEmails
          ? isEmailFromBusinessDomain(values.email)
          : true,
      { message: "Please use a business email address", path: ["email"] },
    )
    // validate that all custom questions are answered and satisfy their requirements
    .superRefine((values, ctx) => {
      values.customQuestions.forEach((_, index) => {
        const question = customQuestions[index];
        const answer = values.customQuestions[index].trim();

        let hasAnswer = !!answer;

        if (question.type === RegistrationQuestionAnswerType.CHECKBOX) {
          hasAnswer = answer === "true";
        }

        // TODO: come back to this when we add support for multiple answers
        // else if (question.type === RegistrationQuestionAnswerType.MULTIPLE_ANSWER) {
        //   hasAnswer = answer?.includes("=true");
        // }

        // validate that required questions are answered
        if (question.isRequired && !hasAnswer) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["customQuestions", index],
            message: "This question is required",
          });
        }

        // validate that questions meet their type requirements
        if (
          question.type === RegistrationQuestionAnswerType.TEXT_LONG &&
          answer.length > MAX_TEXT_LONG_LENGTH
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ["customQuestions", index],
            message: `Answer cannot be more than ${MAX_TEXT_LONG_LENGTH} characters long`,
          });
        }
      });
    });

  return FormSchema;
};

type FormValues = z.infer<ReturnType<typeof generateFormSchema>>;

const RegisterFormView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    data: eventOrNetworkingHub,
    isEvent,
    isNetworkingHub,
  } = useEventOrNetworkingHub();
  const isOnDemand = useEventVideoType() === VideoType.OnDemand;
  const entityName = isEvent ? "event" : "networking hub";
  const formRef = useRef<React.ElementRef<"form">>(null);

  const customQuestions = useMemo(
    () => eventOrNetworkingHub?.registration?.customQuestions ?? [],
    [eventOrNetworkingHub?.registration?.customQuestions],
  );

  const schema = useMemo(
    () =>
      generateFormSchema({
        customQuestions,
        allowOnlyBusinessEmails:
          eventOrNetworkingHub?.registration?.blockedEmails
            ?.allowOnlyBusinessEmails,
      }),
    [customQuestions, eventOrNetworkingHub?.registration?.blockedEmails],
  );

  const form = useFormWithPersistentValidation<FormValues>(
    { schema },
    {
      values: {
        firstName: "",
        lastName: "",
        email: "",
        // show terms checkbox with a value of false if defined or true otherwise
        terms: eventOrNetworkingHub?.registration?.terms ? false : true,
        // show privacy checkbox with a value of false if defined or true otherwise
        privacy: eventOrNetworkingHub?.registration?.privacy ? false : true,
        customQuestions:
          // initialize all the questions w/ empty answers
          eventOrNetworkingHub?.registration?.customQuestions?.map((q) => "") ??
          [],
      },
    },
  );
  const { control, formState } = form;

  const onSubmit = useSubmitHandler(form, formRef);

  const onClickJoinCode = useCallback(
    () =>
      navigate(
        {
          pathname: Routes.registration.enterJoinCode(
            eventOrNetworkingHub?.uid ?? "",
            isEvent,
          ),
          search: location.search,
        },
        { replace: true },
      ),
    [eventOrNetworkingHub?.uid, isEvent, location.search, navigate],
  );

  const isSubmitDisabled = !canSubmitForm({ formState });

  return (
    <RegistrationSidebarView
      as={Form<FormValues>}
      form={form}
      ref={formRef}
      onSubmit={onSubmit}
      iconColor="primary"
      className={cn(isNetworkingHub && "[&>div>h1]:text-xl")}
      title={isOnDemand ? `Register to view` : `Register for the ${entityName}`}
      description={
        isOnDemand
          ? `Fill in the form below to watch the video.`
          : `Fill in the form below to register for this ${entityName}. You will receive a confirmation by email.`
      }
      content={
        <>
          {/* name fields */}
          <div className="flex w-full gap-4">
            <Form.TextField
              id="firstName"
              data-testid="firstName"
              control={control}
              name="firstName"
              label="First Name *"
              placeholder="John"
            />
            <Form.TextField
              id="lastName"
              data-testid="lastName"
              control={control}
              name="lastName"
              label="Last Name *"
              placeholder="Doe"
            />
          </div>
          {/* email */}
          <Form.TextField
            id="email"
            data-testid="email"
            control={control}
            name="email"
            label="Email Address *"
            placeholder="johndoe@email.com"
            LabelProps={{ sx: { mt: 2 } }}
          />
          {/* Custom questions */}
          {customQuestions?.map((question, i) => (
            <CustomQuestion
              key={i}
              control={control}
              index={i}
              question={question}
              setValue={form.setValue}
            />
          ))}
          {/* terms and privacy */}
          {eventOrNetworkingHub?.registration?.terms && (
            <Form.Checkbox
              control={control}
              name="terms"
              label={
                <>
                  {"I agree to the "}
                  <Link
                    target="_blank"
                    href={eventOrNetworkingHub?.registration?.terms}
                    underline="hover"
                  >
                    Terms and Conditions
                  </Link>
                  {"*"}
                </>
              }
            />
          )}
          {eventOrNetworkingHub?.registration?.privacy && (
            <Form.Checkbox
              control={control}
              name="privacy"
              label={
                <>
                  {"I agree to the "}
                  <Link
                    target="_blank"
                    href={eventOrNetworkingHub?.registration?.privacy}
                    underline="hover"
                  >
                    Privacy Policy
                  </Link>
                  {"*"}
                </>
              }
            />
          )}
        </>
      }
      actions={
        <>
          <Button
            type="submit"
            fullWidth
            disabled={isSubmitDisabled}
            loading={formState.isSubmitting}
            loadingPosition="start"
          >
            {formState.isSubmitting ? "Registering..." : "Register"}
          </Button>
          <Button
            type="button"
            variant="text"
            color="secondary"
            fullWidth
            onClick={onClickJoinCode}
            disabled={formState.isSubmitting}
          >
            I already have a Join Code
          </Button>
        </>
      }
    />
  );
};

export default React.memo(RegisterFormView);

export const useSubmitHandler = (
  form: UseFormWithPersistentValidationReturn<FormValues>,
  formRef: React.RefObject<React.ElementRef<"form">>,
) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: eventOrNetworkingHub, isEvent } = useEventOrNetworkingHub();
  const eventHasEnded = useHasEventEnded();
  const { handleJoinEvent } = useJoinEvent();
  const { mutateAsync: registerUser } = useRegisterUser();
  const emitEvent = useEmitEventCallback();

  const { clearPersistentErrors, reset, setPersistentError, handleSubmit } =
    form;

  const submitHandler = useCallback<SubmitHandler<FormValues>>(
    async (values) => {
      try {
        clearPersistentErrors();

        const queryParams = new URLSearchParams(window.location.search);

        const customQuestionsAnswers =
          eventOrNetworkingHub?.registration?.customQuestions?.map<RegistrationQuestionAnswer>(
            (question, index) => {
              if (isUTMFieldQuestion(question)) {
                const UTM_name = PredefinedUTMQuestionIdToFieldMap[question.id];
                const UTM_param =
                  queryParams.get(UTM_name) ??
                  queryParams.get(UTM_name.toUpperCase());

                return { questionId: question.id, answer: UTM_param ?? "" };
              }

              return {
                questionId: question.id,
                answer: values.customQuestions[index],
              };
            },
          );

        await registerUser(
          {
            email: values.email,
            name: `${values.firstName} ${values.lastName}`,
            formUrl: window?.location?.href,
            customQuestionsAnswers,
          },
          {
            onSuccess: (res) => {
              // if user has already registered, redirect to already registered page
              if (res.status === 204) {
                return navigate(
                  {
                    pathname: Routes.registration.alreadyRegistered(
                      eventOrNetworkingHub?.uid ?? "",
                      isEvent,
                    ),
                    search: location.search,
                  },
                  { replace: true, state: { email: values.email } },
                );
              }

              const redirectUrl =
                eventOrNetworkingHub?.registration
                  ?.redirectAfterRegistrationUrl;

              // emit events to parent
              emitEvent(PostMessageEvent.USER_REGISTERED, {
                email: values.email,
                eventId: eventOrNetworkingHub?.uid as string,
                joinCode: res.data.joinCode,
                redirectUrl,
              });

              // redirect immediately to event page if event has ended and there is no redirect url
              if (eventHasEnded && !redirectUrl) {
                return handleJoinEvent(res.data.joinCode);
              }

              // otherwise, redirect to success page if event hasn't ended
              return navigate(
                {
                  pathname: Routes.registration.success(
                    eventOrNetworkingHub?.uid ?? "",
                    isEvent,
                  ),
                  search: location.search,
                },
                { replace: true, state: { joinCode: res.data.joinCode } },
              );
            },
            onError: (err) => {
              const data = err.response?.data;
              const { errorMessage, path } = data ?? {};

              if (!err.isAxiosError || !data || !path || !errorMessage) {
                toast.error(
                  "An error occurred while registering. Please try again.",
                );
                return;
              }

              if (path === "email") {
                toast.error(
                  "An error occurred while registering. Please check your email and try again.",
                );

                setPersistentError("email", {
                  value: values.email,
                  message: errorMessage,
                });
              }
            },
          },
        );
      } catch (err) {
        console.error("Failed to register user:", values, err);
      } finally {
        // reset defaults to current form
        reset(values);
      }
    },
    [
      clearPersistentErrors,
      emitEvent,
      eventHasEnded,
      eventOrNetworkingHub?.registration?.customQuestions,
      eventOrNetworkingHub?.registration?.redirectAfterRegistrationUrl,
      eventOrNetworkingHub?.uid,
      handleJoinEvent,
      isEvent,
      location.search,
      navigate,
      registerUser,
      reset,
      setPersistentError,
    ],
  );

  const errorHandler = useCallback<SubmitErrorHandler<FormValues>>(async () => {
    formRef.current?.scrollTo?.({ top: 0, behavior: "smooth" });
  }, [formRef]);

  return useCallback<React.FormEventHandler<HTMLFormElement>>(
    (e) => handleSubmit(submitHandler, errorHandler)(e),
    [errorHandler, handleSubmit, submitHandler],
  );
};
