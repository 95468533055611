import React, { useEffect } from "react";
import { UserRole } from "../../contracts/user/user";
import { useAmIBlocked, useEvent } from "../../providers/EventProvider";
import { useUser } from "../../providers/UserProvider";
import { useSocketClient } from "../Presence/SocketClientProvider";
import { UserBlockedDialog } from "../UserBlockedDialog";
import { useSetConfig } from "@src/providers/config";
import ErrorPage from "../ErrorPage";
import {
  FeatureFlag,
  useFeatureFlag,
} from "@src/providers/FeatureFlagsProvider";
import { ApiConfig } from "@src/api/api-config";
import { useRouteParams } from "@src/hooks/useRouteParams";
import { useAuthParams } from "@src/providers/QueryParamsProvider";
import { getAuthToken } from "@src/helpers/authSession";

/**
 * Guard against users who have been blocked from the event or who have not registered if registration is enabled
 */
const EventGuard = ({
  children,
  fallback,
}: React.PropsWithChildren<{ fallback: React.ReactNode }>) => {
  const user = useUser();
  const { data: event } = useEvent();
  const { eventId: routeEventId } = useRouteParams();
  const amIBlocked = useAmIBlocked();
  const client = useSocketClient();
  const setConfig = useSetConfig();
  const { joinCode } = useAuthParams();

  const enableAdminRegistrationV2 = useFeatureFlag(
    FeatureFlag.ADMIN_REGISTRATION_V2,
  );
  const isVS1Deprecated = !!useFeatureFlag(FeatureFlag.DEPRECATE_VS1);

  const isUnregistered = !!(user?.userRole === UserRole.Unregistered);

  // If the user is blocked we need to disconnect from the websocket
  // If they are not blocked, connect if not already
  useEffect(() => {
    if (amIBlocked && client.connected) {
      client.disconnect();
    } else if (!amIBlocked && !client.connected) {
      client.connect();
    }
  }, [amIBlocked, client, client.connected]);

  useEffect(() => {
    if (event?.registration?.outsideOfAppEnabled && isUnregistered) {
      setConfig({ cleanUI: true, hybridMode: false });
    }
  }, [event?.registration?.outsideOfAppEnabled, isUnregistered, setConfig]);

  useEffect(() => {
    if (isVS1Deprecated && routeEventId) {
      window.location.href = `${ApiConfig.GetVirtualStageVersion2Prefix()}/event/${routeEventId}${
        window.location.search
      }`;
    }

    if (
      !isVS1Deprecated &&
      event &&
      event.vsVersion === "2.0" &&
      !isUnregistered
    ) {
      const authToken = getAuthToken();
      const params = new URLSearchParams(window.location.search);

      if (authToken) {
        params.set("authToken", authToken);
      }

      if (joinCode) {
        params.set("joinCode", joinCode);
      }

      window.location.href = `${ApiConfig.GetVirtualStageVersion2Prefix()}/event/${
        event.uid
      }?${params.toString()}`;
    }
  }, [event, isUnregistered, isVS1Deprecated, joinCode, routeEventId]);

  if (
    !event ||
    (event.vsVersion === "2.0" && (isVS1Deprecated || !isUnregistered))
  ) {
    return <>{fallback}</>;
  }

  if (
    isUnregistered &&
    !enableAdminRegistrationV2 &&
    !event.isRegistrationModeEnabled &&
    // this could be affected by https://introvoke.atlassian.net/browse/PROD-3185 and https://github.com/introvoke/introvoke-embed/pull/578
    !event.registration?.enabled &&
    !event.registration?.outsideOfAppEnabled
  ) {
    return (
      <ErrorPage
        message="You are not registered for this event. Contact the event organizer for more information."
        showRefresh={false}
      />
    );
  }
  if (amIBlocked) {
    return <UserBlockedDialog show />;
  }

  return <>{children}</>;
};

export default React.memo(EventGuard);
